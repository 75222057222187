import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Component/css/Applicant.css";
import { Input, Button, Select, Form, Row, Col, notification } from "antd";

const API_BASE_URL = "http://recruitmentapi.iykons.com";

const { Option } = Select;

const Job = ({
  handleNext,
  handleBack,
  currentStep,
  formState,
  setFormState,
}) => {
  const [form1] = Form.useForm(); //
  const [softSkill, setSoftSkill] = useState([]);
  const [hardSkill, setHardSkill] = useState([]);
  const [additionalKnownLanguages, setLanguage] = useState([]);
  const [ExperienceData, setExperienceData] = useState({
    motherLanguage: "",
    additionalQualification: "",
  });

  const [educationDTO, setEducationDTO] = useState({
    currentStatus: "",
    qualification: "",
    instituteName: "",
    yearAttained: "",
  });

  const [departmentUserDTO, setDepartmentUserDTO] = useState({
    departmentID: 0,
  });

  const handleChangeExperienceData = (fieldName, value) => {
    setExperienceData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleChangeEducationDTO = (fieldName, value) => {
    setEducationDTO((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleChangeSoftSkills = (value) => {
    setSoftSkill(value);
  };

  const handleChangeHardSkills = (value) => {
    setHardSkill(value);
  };

  const handleChangeAdditionalKnownLanguages = (value) => {
    setLanguage(value);
  };

  const handleChangeDepartment = (value) => {
    setDepartmentUserDTO({
      departmentID: value,
    });
  };

  const location = useLocation();
  const jwtToken = location.state ? location.state.token : null;

  const handleSubmit2 = async () => {
    try {
      await form1.validateFields();
    } catch (error) {
      const errors = error.errorFields.reduce((acc, field) => {
        acc[field.name[0]] = field.errors[0];
        return acc;
      }, {});

      notification.error({
        description:"Please fill in all required fields before proceeding."
      });
      return;
    }
    const data = form1.getFieldsValue()
    const combinedData =  {
      skillUserDTO: {
        softSkill: data.softSkills,
        hardSkill: data.hardSkills,
        language: data.additionalKnownLanguages,
      },
      educationDTO: {
        currentStatus: data.currentstatus,
        qualification: data.qulification,
        instituteName: data.insituteName,
        yearAttained: data.yearAttained,
      },
      departmentUserDTO:{departmentID: data.fieldOfStudy},
      motherLanguage: data.motherLanguage,
      additionalQualification: data.additionalQualification,
  }
    try {
      await axios.post(`${API_BASE_URL}/api/Education/app`, combinedData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      handleNext();
      setFormState({
        educationDTO: {
          currentStatus: combinedData.educationDTO.currentStatus,
          qualification: combinedData.educationDTO.qualification,
          instituteName: combinedData.educationDTO.instituteName,
          yearAttained: combinedData.educationDTO.yearAttained,
        },
        departmentUserDTO: combinedData.departmentUserDTO,
        softSkill: combinedData.skillUserDTO.softSkill,
        hardSkill: combinedData.skillUserDTO.hardSkill,
        language: combinedData.skillUserDTO.language,
        ExperienceData: {
          motherLanguage: combinedData.motherLanguage,
          additionalQualification: combinedData.additionalQualification,
        },
      });
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
        notification.error({
          description: `Server Error: ${error.response.status} - ${
            error.response.data.message || error.response.statusText
          }`,
        });
      } else if (error.request) {
        console.error("Error request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const stepTitles = [
    "Personal Details",
    "Experience Details",
    "Application Questions",
    "Acknowledgement",
    "Reviews",
  ];

  const [departmentOptions, setDepartmentOptions] = useState([]);

  useEffect(() => {
    if(formState !== null ){
      form1.setFieldValue('currentstatus',formState.educationDTO.currentStatus)
      form1.setFieldValue('qulification',formState.educationDTO.qualification)
      form1.setFieldValue('fieldOfStudy',formState.departmentUserDTO.departmentID)
      form1.setFieldValue('insituteName',formState.educationDTO.instituteName)
      form1.setFieldValue('yearAttained',formState.educationDTO.yearAttained)
      form1.setFieldValue('additionalQualification',formState.ExperienceData.additionalQualification)
      form1.setFieldValue('motherLanguage',formState.ExperienceData.motherLanguage)
      form1.setFieldValue('additionalKnownLanguages',formState.language)
      form1.setFieldValue('softSkills',formState.softSkill)
      form1.setFieldValue('hardSkills',formState.hardSkill)
    }
  },[form1, formState]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/Department/GetDepartments`
        );

        const departmentData = response.data.$values;

        if (Array.isArray(departmentData)) {
          setDepartmentOptions(departmentData);
        } else {
          console.error(
            "Error fetching departments: Response data is not an array",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  const [additionalLanguageOptions, setAdditionalLanguageOptions] = useState(
    []
  );
  const [softSkillOptions, setSoftSkillOptions] = useState([]);
  const [hardSkillOptions, setHardSkillOptions] = useState([]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
       
        const response = await axios.get(`${API_BASE_URL}/api/Skill/GetSkills`);
        
      

        const skillData = response.data.$values;

        if (Array.isArray(skillData)) {
          const additionalLanguages = skillData.filter(
            (skill) => skill.skillType === 0
          );
          const softSkills = skillData.filter((skill) => skill.skillType === 1);
          const hardSkills = skillData.filter((skill) => skill.skillType === 2);

          setAdditionalLanguageOptions(additionalLanguages);
          setSoftSkillOptions(softSkills);
          setHardSkillOptions(hardSkills);
        } else {
          console.error(
            "Error fetching skills: Response data is not an array",
            response
          );
        }
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    fetchSkills();
  }, []);

  const customizeRequiredMark = (label, required) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <div className="container" style={{ marginTop: "0px" }}>
      <Form
        form={form1}
        requiredMark={(label, { required }) =>
          customizeRequiredMark(label, required)
        }
        initialValues={{ remember: true }}
      >
        <Row gutter={[24]}>
        <Col span={12}>
  <Form.Item
    label={<span>Current Status</span>}
    name="currentStatus"
    rules={[
      {
        required: true,
        message: "Please select the current status.",
      },
    ]}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
  >
    <Select
      value={formState.educationDTO.currentStatus || undefined}
      onChange={(value) => handleChangeEducationDTO("currentStatus", value)}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Current Status"
    >
      <Option value="Employed">Employed</Option>
      <Option value="Unemployed">Unemployed</Option>
      <Option value="Student">Student</Option>
      <Option value="Intern">Intern</Option>
      <Option value="Looking for Opportunities">Looking for Opportunities</Option>
    </Select>
  </Form.Item>
</Col>

<Col span={12}>
  <Form.Item
    label={<span>Qualification</span>}
    name="qualification"
    rules={[
      {
        required: true,
        message: "Please select the qualification.",
      },
    ]}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
  >
    <Select
      value={formState.educationDTO.qualification || undefined}
      onChange={(value) => handleChangeEducationDTO("qualification", value)}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Qualification"
    >
      <Option value="High School">High School</Option>
      <Option value="Bachelor's Degree">Bachelor's Degree</Option>
      <Option value="Master's Degree">Master's Degree</Option>
      <Option value="Diploma">Diploma</Option>
    </Select>
  </Form.Item>
</Col>

          <Col span={12}>
            <Form.Item
              label={<span>Field Of Study</span>}
              name="fieldOfStudy"
              rules={[
                {
                  required: true,
                  message: "Please select the field of study.",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                value={departmentUserDTO.departmentID}
                onChange={(value) => handleChangeDepartment(value)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Field of Study"
              >
                {departmentOptions.map((department) => (
                  // <div key={department.departmentID}>
                  //   <label>{department.departmentName}</label>
                  // </div>
                  <Option
                    key={department.departmentID}
                    value={department.departmentID}
                  >
                    {department.departmentName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={<span>Insitute Name</span>}
              name="insituteName"
              rules={[
                {
                  required: true,
                  message: "Please enter the institute name.",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="text"
                value={educationDTO.instituteName}
                onChange={(e) =>
                  handleChangeEducationDTO("instituteName", e.target.value)
                }
                placeholder="University"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
  <Form.Item
    label={<span>Year Attained</span>}
    name="yearAttained"
    rules={[
      {
        required: true,
        message: "Please enter the year attained.",
      },
      {
        pattern: /^[0-9]{4,8}$/,
        message: "Year attained must be a number between 4 and 8 digits.",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || parseInt(value) <= new Date().getFullYear()) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("Year attained must be a past year.")
          );
        },
      }),
    ]}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
  >
    <Input
      type="text"
      value={educationDTO.yearAttained}
      onChange={(e) =>
        handleChangeEducationDTO("yearAttained", e.target.value)
      }
      placeholder="Year Attained"
    />
  </Form.Item>
</Col>

          <Col span={12}>
            <Form.Item
              label={<span>Additional Qualification</span>}
              name="additionalQualification"
              rules={[
                {
                  required: true,
                  message: "Please enter the additional qualification.",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="additionalQualification"
                value={ExperienceData.additionalQualification}
                onChange={(e) =>
                  handleChangeExperienceData(
                    "additionalQualification",
                    e.target.value
                  )
                }
                placeholder="additional Qualification"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Mother Language
                  {/* <span className="required-asterisk">*</span> */}
                </span>
              }
              name="motherLanguage"
              rules={[
                {
                  required: true,
                  message: "Please enter the mother language.",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="text"
                value={ExperienceData.motherLanguage}
                onChange={(e) =>
                  handleChangeExperienceData("motherLanguage", e.target.value)
                }
                placeholder="Mother Language"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Additional Known Languages
                  {/* <span className="required-asterisk">*</span> */}
                </span>
              }
              name="additionalKnownLanguages"
              rules={[
                {
                  required: true,
                  message: "Please select the additional languages.",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                value={additionalKnownLanguages.additionalKnownLanguages}
                onChange={(value) =>
                  handleChangeAdditionalKnownLanguages(value)
                }
                placeholder="additional Languages"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {additionalLanguageOptions.map((skill) => (
                  <Option key={skill.skillid} value={skill.skillid}>
                    {skill.skillName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Soft Skills
                  {/* <span className="required-asterisk">*</span> */}
                </span>
              }
              name="softSkills"
              rules={[
                { required: true, message: "Please select the soft skills." },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                value={softSkill.softSkills}
                onChange={(value) => handleChangeSoftSkills(value)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="SoftSkills"
              >
                {softSkillOptions.map((skill) => (
                  <Option key={skill.skillid} value={skill.skillid}>
                    {skill.skillName}
                  </Option>
                ))}
              </Select>

              {/* Similar structure as Additional Known Languages */}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Hard Skills
                  {/* <span className="required-asterisk">*</span> */}
                </span>
              }
              name="hardSkills"
              rules={[
                { required: true, message: "Please select the hard skills." },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                value={hardSkill.hardSkills}
                onChange={(value) => handleChangeHardSkills(value)}
                placeholder="Select hard skills"
                showSearch
                optionFilterProp="children"
              >
                {hardSkillOptions.map((skill) => (
                  <Option key={skill.skillid} value={skill.skillid}>
                    {skill.skillName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <div
            className="col-md-12"
            style={{ textAlign: "right", marginTop: "20px" }}
          >
            {currentStep > 0 && (
              <Button
                type="button"
                onClick={handleBack}
                className="btn btn-primary"
                style={{ marginRight: "10px" }}
              >
                Back
              </Button>
            )}
            {currentStep < stepTitles.length - 1 && (
              <Button
                type="button"
                onClick={handleSubmit2}
                className="btn btn-primary"
              >
                Next
              </Button>
            )}
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default Job;
