import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Component/Login";
import ApplicantForm from "./Component/ApplicantForm";
import Signup from "./Component/SignUp";
import Review from "./Component/Review";
import ManageUsers from "./Component/Dashboard/Manageusers";
import All from "./Component/Dashboard/AllResume";
import New from "./Component/Dashboard/NewResume";
import Accept from "./Component/Dashboard/AcceptResume";
import Approve from "./Component/Dashboard/ApprovedResume";
import Reject from "./Component/Dashboard/RejectResume";
import ForgotPassword from "./Component/ForgotPassword";
import ResetPassword from "./Component/ResetPassword";
import AdminLogin from "./Component/Dashboard/login";
import AdminDashboard from "./Component/Dashboard/AdminDashboard ";
import PrivateRoute from "./Component/PrivateRoute";
import { AuthProvider } from "./Component/AuthProvider";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Applicant" element={<ApplicantForm />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/Review" element={<Review />} />
          <Route path="/account/reset-password" element={<ResetPassword />} />
          <Route path="/admin" element={<AdminLogin />} />

          {/* Protected admin routes */}
          <Route path="/admin" element={<PrivateRoute />}>
  <Route path="dashboard" element={<AdminDashboard />} />
  <Route path="all" element={<All />} />
  <Route path="new" element={<New />} />
  <Route path="accept" element={<Accept />} />
  <Route path="approve" element={<Approve />} />
  <Route path="reject" element={<Reject />} />
  <Route path="manage" element={<ManageUsers />} />
</Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;