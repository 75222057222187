import React, { useState, useEffect } from "react"
import "../css/Dashboard.css"
import axios from "axios"
import { Select } from "antd"
import customLogo from "../../Component/mainlogo.png"
import "react-circular-progressbar/dist/styles.css"

import { NavLink } from "react-router-dom"

function Approve() {
  const { Option } = Select
  const [resumeData, setResumeData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("All Resume")
  const filteredResumes =
    selectedCategory === "All Resume"
      ? resumeData
      : resumeData.filter((resume) => {
          const position = resume.fileUploadResponses.$values[0]?.positionId

          switch (selectedCategory) {
            case "UI/UXDesigner":
              return position === 3
            case "Frontend-Developer":
              return position === 1
            case "QualityAssurance":
              return position === 5

            case "FullStackDeveloper":
              return position === 4
            default:
              return true
          }
        })
        const [acceptData, setAcceptData] = useState({
          subject: "Congratulations! You've Been Selected for the Screening Process at Iykons",
          content: `
            <p>Dear Candidate,</p>
        
            <p>We are thrilled to inform you that your application has been successfully reviewed, and you have been selected to proceed to the next phase of our recruitment process!</p>
        
            <p>At <strong>Iykons</strong>, we are always on the lookout for talented and passionate individuals like you who are eager to contribute to our innovative journey. Your skills and experience caught our attention, and we are excited to explore the possibility of you joining our dynamic team.</p>
        
            <h3>What's Next?</h3>
            <p>You are now invited to participate in our screening process, where we will get to know you better and assess how well your talents align with the opportunities at Iykons. This is your chance to shine and showcase what makes you the perfect fit for our team.</p>
        
            <p>We'll be sending you more details about the screening process, including the schedule and format. Please keep an eye on your inbox!</p>
        
            <h3>Why Iykons?</h3>
            <p>At Iykons, we believe in fostering a collaborative environment where innovation thrives. We are committed to empowering our team members to grow both professionally and personally. If you're passionate about making a difference and being part of something bigger, then Iykons is the place for you.</p>
        
            <p>Thank you for considering Iykons as your next career move. We can't wait to learn more about you during the screening process.</p>
        
            <p><strong>Welcome to the Future of Innovation. Welcome to Iykons.</strong></p>
        
            <p>Best regards,<br />
            <strong>HR Team</strong><br />
            Iykons</p>
          `
        });
        
    const handleAccept = async (id) => {
      try {
        const resume = resumeData.find(r => r.id === id);
        if (!resume) {
          console.error("Resume not found");
          return;
        }
    
        const candidateName = `${resume.firstName} ${resume.lastName}`;
       
    
        const personalizedContent = acceptData.content
          .replace("[Candidate_Name]", candidateName)
          .replace("[selectedCategory]", selectedCategory);
    
        const personalizedAcceptData = {
          ...acceptData,
          content: personalizedContent
        };
    
        await axios.post(
          `${API_BASE_URL}/api/Account/send-message/${id}`,
          personalizedAcceptData
        );
    
        setResumeData(prevData => prevData.filter(resume => resume.id !== id));
        // You can add a success message here if you want
        console.log("Email sent successfully and resume removed from approved list.");
      } catch (error) {
        console.error("Error sending email:", error);
        // You can add an error message here if you want
      }
    };
   
  
  const API_BASE_URL = "http://recruitmentapi.iykons.com"

  useEffect(() => {
    fetchResumeData3()
  }, [])

  const fetchResumeData3 = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/User/APROVE`)
      setResumeData(response.data.$values)
    } catch (error) {
      console.error("Error fetching resume data:", error)
    }
  }
  const categoryDropDownList = [
    { label: "All", value: "All Resume" },
    { label: "UI/UXDesigner", value: "UI/UXDesigner" },
    { label: "Frontend-Developer", value: "Frontend-Developer" },
    { label: "QualityAssurance", value: "QualityAssurance" },
    { label: "FullStackDeveloper", value: "FullStackDeveloper" },
  ]
  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link
        href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
        rel="stylesheet"
      />

      <link rel="stylesheet" href="style.css" />
      <title>JobAdmin</title>

      <section id="sidebar">
        <a href="/admin/dashboard"  className="brand">
          <i className="bx bxs-smile" />
          <span className="text">JobAdmin</span>
        </a>
        <ul className="side-menu top">
          <li className="active">
            <a href="/admin/dashboard" >
              <i className="bx bxs-dashboard" />
              <span className="text">Dashboard</span>
            </a>
          </li>
        </ul>

        <ul className="side-menu">
        <li>
    <NavLink to="/admin/all">
      <span className="text">All Resume</span>
    </NavLink>
  </li>
  <li>
    <NavLink to="/admin/new">
      <span className="text">New Resume</span>
    </NavLink>
  </li>
  <li>
    <NavLink to="/admin/accept">
      <span className="text">Accept Resume</span>
    </NavLink>
  </li>
  <li>
    <NavLink to="/admin/approve">
      <span className="text">Approved Resume</span>
    </NavLink>
  </li>
  <li>
    <NavLink to="/admin/reject">
      <span className="text">Reject Resume</span>
    </NavLink>
  </li>
  <li>
    <NavLink to="/admin/manage">
      <span className="text">Manage Users</span>
    </NavLink>
  </li>
        </ul>
      </section>

      <section id="content">
        <nav>
          <form action="#"></form>

          <a href="/admin/dashboard"  className="profile">
            <img src={customLogo} alt="Custom Logo" />
          </a>
        </nav>

        <main>
          <div className="head-title">
            <div className="left">
              <h1>Dashboard</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/admin/dashboard" >Dashboard</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                </li>
                <li>Approved Resumes</li>
              </ul>
            </div>
          </div>

          <div className="table-data">
            <div className="order">
              <div className="head">
                <h3>Resumes</h3>
                <Select
                  className="nav-link"
                  style={{ width: 200 }}
                  onChange={(value) => setSelectedCategory(value)}
                  defaultValue="All Resume"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {categoryDropDownList.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Resume</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResumes.map((resume) => (
                    <tr key={resume.id}>
                      <td>
                        {resume.firstName} {resume.lastName}
                      </td>
                      <td>
                        {resume.fileUploadResponses.$values.map((file) => (
                          <div key={file.id}>
                            <a
                              href={file.filePath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Resume
                            </a>
                          </div>
                        ))}
                      </td>

                      <td>
                        <button
                          onClick={() => handleAccept(resume.id)}
                          style={{
                            padding: "5px 10px",
                            fontSize: "14px",
                            color: "white",
                            marginRight: "5px",
                            backgroundColor: "#3C91E6",

                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "green")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "#3C91E6")
                          }
                        >
                          SendMail
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </section>
    </>
  )
}

export default Approve
